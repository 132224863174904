<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h3>Essential Links</h3>
  </div>
</template>

<!-- import Css -->
<style src="@/css/home.css" />

<script lang="ts">
	import { defineComponent } from "vue";
	export default defineComponent({
	  name: "HelloWorld",
	  props: { msg: String }
	});
</script>